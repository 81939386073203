/* eslint-disable */
import {createStore} from 'vuex'
import geral from './modules/geral'
import token from "./modules/token";
import planos from "./modules/planos";
import cidades from "./modules/cidades";
import usuarios from './modules/usuarios'
import clientes from "./modules/clientes";
import tipos_solucoes from "./modules/tipos_solucoes";
import galerias from "./modules/galerias";
import banners from "./modules/banners";
import solucoes from "./modules/solucoes";

export default createStore({
   modules: {
      geral,
      token,
      planos,
      cidades,
      clientes,
      galerias,
      usuarios,
      tipos_solucoes,
      banners,
      solucoes



   }
})
