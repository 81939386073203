/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export  class Banner {

  constructor () {
    this.banner_id = uuidV4();
    this.link = ''
    this.efeito= 'fade'
    this.horizontal_align= 10
    this.ordem = 0
    this.imagens= [];
    this.urls_imagem= [];
  }
}
