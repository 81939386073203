/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";


export const  getBaseSCH = (campo, valor, condicao)=>{
  var filtro = new Filtro();
  filtro.perPage = 1;
  filtro.filters.addFilter(campo,valor,condicao);
  return filtro
};


export const GaleriaSCH = (perPage)=> {
    var filtro = new Filtro();
    filtro.perPage = perPage;
    // filtro.fields = ['*'];
    filtro.fields = ['tipos_solucoes.titulo','galeria.tp_solucao_id'];
    filtro.join.addLeft('tipos_solucoes','tipos_solucoes.tp_solucao_id','galeria.tp_solucao_id')
  return filtro

};
