/* eslint-disable */
import {lista, nao_autorizado, pegaDados, processar} from '../../services/GenericAPI'
import {Filtro} from "../../models/filtro/filtro";
import {BaseDescSCH, BaseSCH, getBaseSCH} from "../../search/BaseSCH";
import {Cliente} from "../../models/cliente";
import {ClienteSCH} from "../../search/ClienteSCH";

const url = '/cliente'

const state = {
  all: [],
  allList: [],
  cliente: new Cliente(),
  filtro: new Filtro()
}

const getters = {
  listaAllClientes: (state) => {
    return state.allList
  },

  listaClientes: (state) => {
    return state.all
  },
  pegaCliente: (state) => {
    return state.cliente
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.search = [];
  state.filtro.filters.addSearch(['nome'], '%' + search + '%', 'ilike')
  },
  async getItem({commit}, {cliente_id, onSucesso,onErro}) {
    try {
      var filtro = getBaseSCH('cliente_id', cliente_id, '=')
      const list = (await pegaDados(url+'/get', filtro)).data
      if (list.data[0] !== undefined) {
        commit('SET_CLIENTE', list.data[0])
        onSucesso()
      } else {
        onErro()
      }
    } catch (error) {
      onErro()
      console.log(error)
    }
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', ClienteSCH(perPage))
  },

  async setAllListClientes({commit},{onSucesso, onErro}) {
    try {
      const list = (await lista(url + '/get_all')).data
      commit('SET_ALL_LIST', list)
      onSucesso()
    } catch (error) {
      console.log(error)
      onErro()
    }
  },
  async setAllClientes({commit},{onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url + '/get', state.filtro)).data

      commit('SET_CLIENTES', list)
      onSucesso()
    } catch (error) {
      console.log(error)
      onErro()
      nao_autorizado(error)
    }
  },
  async processarCliente({commit}, {acao,onSucesso,onErro}) {
    try {

      let res = await processar(url + (acao === 'gravar' ? '/commit' : '/delete'), state.cliente, acao)

      onSucesso()
      this.dispatch('geral/setMsgSuccess', res.data.message)
    } catch (error) {
      console.log('error: ',error)
      onErro()
      this.dispatch('geral/setObjMsgError', error.response.data)
    }
  },
  setCliente({commit}, clientes) {
    commit('SET_CLIENTE', clientes)
  },
}

const mutations = {

  SET_ALL_LIST(state, filtro) {
    state.allList = filtro
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_CLIENTES(state, clientes) {
    state.all = clientes
  },
  SET_CLIENTE(state, clientes) {
    state.cliente = clientes
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
