<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
			<div id="modal_padrao">
						<v-dialog id="dialogsmodal" style="z-index: 1000" v-model="Mostrar" :width="fullscreen? '100%' :mobileModal(width)" :theme="darkTheme"
																:fullscreen="fullscreen"
																:scrim="false">
									<v-card id="modalcadastro" :theme="darkTheme"
																>
												<!--style="max-height: 600px"-->
												<div style="background: #620d16; color: white;height: 40px;padding: 1%  1%  1% 15px; ">
															<span style="font-size: 1rem"> {{ getTitulo() }}</span>
															<slot name="close"></slot>
												</div>
												<v-card-text id="modal_conteudo">
														<div>
																	<v-container>
																				<slot name="formulario"></slot>
																	</v-container>
														</div>
												</v-card-text>
												
												<v-card-actions v-if="shoActions" :style="'position: sticky;bottom: 0;  width: 100%; border-top: 1px solid gray; background:' + (isdarkTheme ?'#212121' : 'white' ) ">
																		<slot name="actions"></slot>
												</v-card-actions>
												
									</v-card>
								
						</v-dialog>
						
			</div>
</template>

<script>
   /* eslint-disable */

   import {mapState} from "vuex";

   export default {
      props: {
         titulo: {
            default: ''
         },
         modal: {
            default: false
         },

         persistent: {
            default: true
         },
         fullscreen: {
            default: false
         },
         isCadastro: {
            default: false
         },
									shoActions: {
            default: true
         },
         width: {
            default: '50%'
         }
      },
      name: 'ModalCadastro',

      data() {
         return {
            overlay: false,
            classe: '',
            margem_left: '25',

         }
      },
      methods: {
         mobileModal(size) {
            var width = window.screen.width
            if (width <= 400) {
               size = '100%'
            } else if (width >= 400 && width <= 600) {
               size = '90%'
            } else if (width >= 601 && width <= 900) {
               size = '80%'
            } else if (width >= 901 && width <= 1300) {
               size = '70%'
            }
            return size
         },

         validarCampo(campo) {
            if (campo !== undefined && campo !== null && campo !== '') {
               return true
            } else {
               return false
            }
         },
         getTitulo() {
            if (this.validarCampo(this.titulo)) {
               return this.titulo
            } else {
               return this.$router.currentRoute.meta
            }
         },
      },
      computed: {
         ...mapState('geral', {
            isdarkTheme: 'isdarkTheme',
            darkTheme: 'darkTheme',
         }),
         Mostrar: {
            get() {
               return this.modal
            },
            set() {
               return this.Mostrar
            }
         },
      },
      mounted() {
         var modal_conteudo = document.getElementById('modal_conteudo')
									if (modal_conteudo != null){
            // console.log(modal_conteudo.clientHeight);
            // modal_conteudo.style = 'background: red;'
         }
         
						},
      watch: {
         Mostrar: function () {
            if (this.Mostrar) {
               let _this = this
               var inter = setInterval(function () {
                  let elm = document.getElementById('modalcadastro')
                  if (elm !== null) {
                     elm.addEventListener('keydown', function (e) {
                        e = e || window.event;
                        const code = e.which || e.keyCode;

                        if (code === 27) {
                           _this.$emit('fechar', false)
                           e.preventDefault()
                        }
                     })
                  }

                  clearInterval(inter)
               }, 500)

            }
         },
      }
   }
</script>

<style lang="scss">
			
			.v-toolbar--dense .v-toolbar__content, .v-toolbar--dense .v-toolbar__extension {
						font-size: 16px !important;
			}


</style>
