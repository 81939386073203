/* eslint-disable */
import {v4 as uuidV4} from 'uuid'

export class Cidade {

  constructor () {
    this.cidade_id = uuidV4();
    this.nome = ''
    this.bloqueada = false
    this.modem = false
    this.tem_desconto = false
  }
}
