/* eslint-disable */
import {lista, nao_autorizado, pegaDados, processar} from '../../services/GenericAPI'
import {Filtro} from "../../models/filtro/filtro";
import {Plano} from '../../models/plano'
import {BaseDescSCH, getBaseSCH} from "../../search/BaseSCH";
import {PlanoSCH} from "../../search/PlanoSCH";

const url = '/planos'

const state = {
  all: [],
  allList: [],
  plano: new Plano(),
  filtro: new Filtro()
}

const getters = {
  listaAllPlanos: (state) => {
    return state.allList
  },

  listaPlanos: (state) => {
    return state.all
  },
  pegaPlano: (state) => {
    return state.plano
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(['cidades.nome','descricao','valor'], '%' + search + '%', 'ilike')
  },
  async getItem({commit}, {planos_id, onSucesso,onErro}) {
    try {
      var filtro = getBaseSCH('plano_id', planos_id, '=')
      const list = (await pegaDados(url+'/get', filtro)).data
      if (list.data[0] !== undefined) {
        commit('SET_PLANO', list.data[0])
        onSucesso()
      } else {
        onErro()
      }
    } catch (error) {
      onErro()
      console.log('error: ',error)
    }
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', PlanoSCH(perPage))
  },

  async setAllListPlanos({commit},{onSucesso, onErro}) {
    try {
      const list = (await lista(url + '/all')).data
      commit('SET_ALL_LIST', list)
      onSucesso()
    } catch (error) {
      console.log('error: ',error)
      onErro()
    }
},
  async setAllPlanos({commit},{onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url + '/get', state.filtro)).data
      commit('SET_PLANOS', list)
      onSucesso()
    } catch (error) {
      console.log('error: ',error)
      onErro()
      nao_autorizado(error)
    }
  },
  async processarPlano({commit}, {acao,onSucesso,onErro}) {
    try {
      let res = await processar(url + (acao === 'gravar' ? '/commit' : '/delete'), state.plano, acao)

      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      onErro()
      console.log('error: ',error)
      this.dispatch('geral/setObjMsgError', error.response.data)
    }
  },
  setPlano({commit}, plano) {
    commit('SET_PLANO', plano)
  },
}

const mutations = {

  SET_ALL_LIST(state, filtro) {
    state.allList = filtro
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_PLANOS(state, planos) {
    state.all = planos
  },
  SET_PLANO(state, plano) {
    state.plano = plano
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
