/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";


export const  getBaseSCH = (campo, valor, condicao)=>{
  var filtro = new Filtro();
  filtro.perPage = 1;
  filtro.filters.addFilter(campo,valor,condicao);
  return filtro
};


export const CidadeSCH = (perPage)=> {
    var filtro = new Filtro();
    filtro.perPage = perPage;
    filtro.fields = ['*'];
  filtro.addOrder('nome','asc')
  return filtro

};
