/* eslint-disable */
export default {
  data() {
    return {
      setPage: false,
      show_list: false,
      itemsPerPage: 15
    }
  },
  methods: {
    set_por_paginas(itemsPerPage) {
      this.itemsPerPage = itemsPerPage
    },
  }
}
