<template>
  <v-app id="app" style="background: transparent">
    <v-main class="backmain" style="background: transparent">
      <router-view/>
    
    </v-main>
    
  </v-app>
</template>
<script>
  export default {
    name: 'App',
  }
</script>
