/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export  class Usuario {
  constructor () {
    this.user_id = uuidV4()
    this.name = ''
    this.email = ''
    this.password = ''
  }
}
