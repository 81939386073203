/* eslint-disable */
import {lista, nao_autorizado, pegaDados, processar} from '../../services/GenericAPI'
import {Filtro} from "../../models/filtro/filtro";
import {BaseDescSCH, BaseSCH, getBaseSCH} from "../../search/BaseSCH";
import {TipoSolucao} from "../../models/tipo_solucao";
import {TipoSolucaoSCH} from "../../search/TipoSolucaoSCH";

const url = '/tipo_solucao'

const state = {
  all: [],
  allList: [],
  tipo_solucao: new TipoSolucao(),
  filtro: new Filtro()
}

const getters = {
  listaAllTiposSolucoes: (state) => {
    return state.allList
  },

  listaTiposSolucoes: (state) => {
    return state.all
  },
  pegaTipoSolucao: (state) => {
    return state.tipo_solucao
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.search = [];
  state.filtro.filters.addSearch(['nome'], '%' + search + '%', 'ilike')
  },
  async getItem({commit}, {tp_solucao_id, onSucesso,onErro}) {
    try {
      var filtro = getBaseSCH('tp_solucao_id', tp_solucao_id, '=')
      const list = (await pegaDados(url+'/get', filtro)).data
      if (list.data[0] !== undefined) {
        commit('SET_TIPO_SOLUCAO', list.data[0])
        onSucesso()
      } else {
        onErro()
      }
    } catch (error) {
      onErro()
      console.log(error)
    }
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', TipoSolucaoSCH(perPage))
  },

  async setAllListTiposSolucoes({commit},{onSucesso, onErro}) {
    try {
      const list = (await lista(url + '/get_tipos_solucoes')).data
      commit('SET_ALL_LIST', list)
      onSucesso()
    } catch (error) {
      console.log(error)
      onErro()
    }
  },
  async setAllTiposSolucoes({commit},{onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url + '/get', state.filtro)).data

      commit('SET_TIPOS_SOLUCOES', list)
      onSucesso()
    } catch (error) {
      console.log(error)
      onErro()
      nao_autorizado(error)
    }
  },
  async processarTipoSolucao({commit}, {acao,onSucesso,onErro}) {
    try {

      let res = await processar(url + (acao === 'gravar' ? '/commit' : '/delete'), state.tipo_solucao, acao)

      onSucesso()
      this.dispatch('geral/setMsgSuccess', res.data.message)
    } catch (error) {
      console.log('error: ',error)
      onErro()
      this.dispatch('geral/setObjMsgError', error.response.data)
    }
  },
  setTipoSolucao({commit}, tipo_solucaos) {
    commit('SET_TIPO_SOLUCAO', tipo_solucaos)
  },
}

const mutations = {

  SET_ALL_LIST(state, filtro) {
    state.allList = filtro
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_TIPOS_SOLUCOES(state, tipo_solucaos) {
    state.all = tipo_solucaos
  },
  SET_TIPO_SOLUCAO(state, tipo_solucaos) {
    state.tipo_solucao = tipo_solucaos
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
