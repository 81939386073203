import {createRouter, createWebHistory} from 'vue-router'

const router = createRouter({
   history: createWebHistory(process.env.BASE_URL),
   routes: [
      {path: '/', name: 'Login',    meta: {title: 'Login'},component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')},
      {path: '/admin/login', name: 'LoginAdmin',    meta: {title: 'Login'},component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')},
      {
         path: '/dash',
         name: 'Dash',
         meta: {title: 'Dash'},
         component: () => import(/* webpackChunkName: "Dash" */ '../views/Dash.vue'),
         children: [

            {
               path: '/principal',
               name: 'Principal',
               meta: {title: 'Principal'},
               component: () => import(/* webpackChunkName: "about" */ '../views/Principal.vue')
            },
            {
               path: '/cidades',
               name: 'Cidades',
               meta: {title: 'Cidades'},
               component: () => import(/* webpackChunkName: "Cidades" */ '../views/Cadastros/cidades/Cidades.vue')
            },
            {
               path: '/planos',
               name: 'Planos',
               meta: {title:'Planos'},
               component: () => import(/* webpackChunkName: "Planos" */ '../views/Cadastros/planos/Planos.vue')
            },
            {
               path: '/usuario',
               name: 'Usuário',
               meta: {title:'Usuários'},
               component: () => import(/* webpackChunkName: "Usuario" */ '../views/Cadastros/usuarios/Usuario.vue')
            },
            {
               path: '/cliente',
               name: 'Cliente',
               meta: {title:'Cliente'},
               component: () => import(/* webpackChunkName: "Cliente" */ '../views/Cadastros/clientes/Clientes.vue')
            },
            {
               path: '/banner',
               name: 'Banner',
               meta: {title:'Banner'},
               component: () => import(/* webpackChunkName: "Banner" */ '../views/Cadastros/banner/Banner.vue')
            },
            {
               path: '/tipo_solucao',
               name: 'TipoSolucao',
               meta: {title:'Tipo de Solução'},
               component: () => import(/* webpackChunkName: "Cliente" */ '../views/Cadastros/tipo_solucao/TiposSolucoes.vue')
            },
            {
               path: '/galeria',
               name: 'Galeria',
               meta: {title:'Galeria'},
               component: () => import(/* webpackChunkName: "Galeria" */ '../views/Cadastros/galeria/Galeria.vue')
            },
            {
               path: '/solucao',
               name: 'Solucao',
               meta: {title:'Solução'},
               component: () => import(/* webpackChunkName: "Solucoes" */ '../views/Cadastros/solucoes/Solucoes.vue')
            },
            {
               path: '/solucao/cadastro',
               name: 'SolucoesCadastro',
               meta: {title:'Soluções'},
               component: () => import(/* webpackChunkName: "SolucoesCadastro" */ '../views/Cadastros/solucoes/SolucoesCadastro.vue')
            },

         ]
      },
      {
         path: '/:pathMatch(.*)*',
         name: 'not-found',
         meta: {title:'Not Found'},
         component: () => import(/* webpackChunkName: "404" */ '../views/NotFound/NotFound.vue')
      },

   ]
});

router.beforeEach((to, from) => {
   document.title = (to.meta?.title + ' - Prodata Admin')  ?? 'Web Prodata Admin'
})

export default router
