/* eslint-disable */
import {lista, nao_autorizado, pegaDados, processar} from '../../services/GenericAPI'
import {Filtro} from "../../models/filtro/filtro";
import {BaseDescSCH, BaseSCH, getBaseSCH} from "../../search/BaseSCH";
import {Galeria} from "../../models/galeria";
import {GaleriaSCH} from "../../search/GaleriaSCH";

const url = '/galeria'

const state = {
  all: [],
  allList: [],
  galeria: new Galeria(),
  filtro: new Filtro()
}

const getters = {
  listaAllGalerias: (state) => {
    return state.allList
  },

  listaGalerias: (state) => {
    return state.all
  },
  pegaGaleria: (state) => {
    return state.galeria
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.search = [];
  state.filtro.filters.addSearch(['tipos_solucoes.titulo'], '%' + search + '%', 'ilike')
  },
  async getItem({commit}, {galeria_id, onSucesso,onErro}) {
    try {
      var filtro = getBaseSCH('galeria_id', galeria_id, '=')
      const list = (await pegaDados(url+'/get', filtro)).data
      if (list.data[0] !== undefined) {
        commit('SET_GALERIA', list.data[0])
        onSucesso()
      } else {
        onErro()
      }
    } catch (error) {
      onErro()
      console.log(error)
    }
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', GaleriaSCH(perPage))
  },

  async setAllListGalerias({commit},{onSucesso, onErro}) {
    try {
      const list = (await lista(url + '/get_all')).data
      commit('SET_ALL_LIST', list)
      onSucesso()
    } catch (error) {
      console.log(error)
      onErro()
    }
  },
  async setAllGalerias({commit},{onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url + '/get', state.filtro)).data

      commit('SET_GALERIAS', list)
      onSucesso()
    } catch (error) {
      console.log(error)
      onErro()
      nao_autorizado(error)
    }
  },
  async processarGaleria({commit}, {acao,onSucesso,onErro}) {
    try {

      let res = await processar(url + (acao === 'gravar' ? '/commit' : '/delete'), state.galeria, acao)

      onSucesso()
      this.dispatch('geral/setMsgSuccess', res.data.message)
    } catch (error) {
      console.log('error: ',error)
      onErro()
      this.dispatch('geral/setObjMsgError', error.response.data)
    }
  },
  setGaleria({commit}, galerias) {
    commit('SET_GALERIA', galerias)
  },
}

const mutations = {

  SET_ALL_LIST(state, filtro) {
    state.allList = filtro
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_GALERIAS(state, galerias) {
    state.all = galerias
  },
  SET_GALERIA(state, galerias) {
    state.galeria = galerias
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
