/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export  class TipoSolucao {

  constructor () {
    this.tp_solucao_id = uuidV4();
    this.descricao= '';
    this.titulo= '';
    this.resumo= '';
    this.link= '';
    this.ordem= 0;
    this.destaque= false;
    this.tem_galeria= false;
    this.imagens= [];
    this.urls_imagem= [];
  }
}
